import React from 'react'
import { useGetCandidatePerformanceProfile } from '@src/api/recruitment/interviews'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { ResultHeader } from '@src/pages/Forms/Candidate/Performance/components/ResultHeader'
import { OverviewHeader } from '@src/pages/Forms/Candidate/Performance/components/OverviewHeader'
import { OverallGrade } from '@src/pages/Forms/Candidate/Performance/components/OverallGrade'

type PerformanceOverviewProps = {
  round?: InterviewRoundInterface
}

export const PerformanceOverview = ({ round }: PerformanceOverviewProps) => {
  const { data, isLoading } = useGetCandidatePerformanceProfile(round?.id)
  return (
    <OverviewHeader
      overallGrade={<OverallGrade grade={data?.suggested_grade} loading={isLoading} />}
      results={
        <>
          <ResultHeader
            loading={isLoading}
            value={data?.skill_ratings?.skill?.average_rating}
            type="skills"
          />
          <ResultHeader
            loading={isLoading}
            value={data?.skill_ratings?.value?.average_rating}
            type="values"
          />
          <ResultHeader
            label="Expected seniority"
            loading={isLoading}
            type="seniority"
            value={data?.suggested_seniority?.name}
          />
        </>
      }
      resultsLabel="Assessment"
    />
  )
}
