import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { InterviewRoundSummaryRatingInterface } from '@src/interfaces/interviewTool'
import { HStack } from '@revolut/ui-kit'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { ResultTag } from '@src/pages/Forms/Candidate/Performance/components/ResultTag'

export const performanceHiringName: ColumnInterface<InterviewRoundSummaryRatingInterface> =
  {
    type: CellTypes.text,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Name',
  }

export const performanceHiringInterviewers: ColumnInterface<InterviewRoundSummaryRatingInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'interviewer_ratings',
    dataPoint: 'interviewer_ratings',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Interviewer',
    insert: ({ data }) => {
      if (!data.interviewer_ratings) {
        return null
      }
      const interviewers = data.interviewer_ratings.flatMap(
        interview => interview.interviewer,
      )
      return (
        <HStack gap="s-4">
          {interviewers.map(interviewer => {
            const { id } = interviewer
            return <UserWithAvatar key={id} compact {...interviewer} />
          })}
        </HStack>
      )
    },
  }

export const performanceHiringResult: ColumnInterface<InterviewRoundSummaryRatingInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'average_rating',
    dataPoint: 'average_rating',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Result',
    insert: ResultTag,
  }
