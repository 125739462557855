import React, { useMemo, useState } from 'react'
import { VStack, Widget } from '@revolut/ui-kit'
import {
  InterviewRoundSummaryRatingInterface,
  PerformanceHiringRoundSummaryInterface,
  PerformanceHiringRoundSummaryRatingInterface,
} from '@src/interfaces/interviewTool'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import {
  performanceHiringName,
  performanceHiringInterviewers,
  performanceHiringResult,
} from '@src/constants/columns/performanceHiring'
import { TableNames } from '@src/constants/table'
import { PerformanceSection } from '@src/pages/Forms/Candidate/Performance/components/PerformanceSection'
import {
  FilterBar,
  FilterBarSkeleton,
} from '@src/pages/Forms/Candidate/Performance/Summary/FilterBar'

const row = {
  cells: [
    {
      ...performanceHiringName,
      width: 100,
    },
    {
      ...performanceHiringInterviewers,
      width: 50,
    },
    {
      ...performanceHiringResult,
      width: 50,
    },
  ],
}

type PerformanceSectionTableProps = {
  data?: InterviewRoundSummaryRatingInterface[]
  name:
    | TableNames.PerformanceHiringSummaryDeliverables
    | TableNames.PerformanceHiringSummarySkills
    | TableNames.PerformanceHiringSummaryValues
}

const PerformanceSectionTable = ({ data, name }: PerformanceSectionTableProps) => {
  return (
    <AdjustableTable<InterviewRoundSummaryRatingInterface>
      data={data || []}
      name={name}
      count={data?.length || 0}
      hideCountAndButtonSection
      noDataMessage="No data"
      row={row}
    />
  )
}

type PerformanceSectionsProps = {
  performanceRoundSummary?: PerformanceHiringRoundSummaryInterface
}

const filterRatingsByInterviewers = (
  interviewersFilter: number[],
  performanceRoundSummaryRating?: PerformanceHiringRoundSummaryRatingInterface,
) => {
  if (!interviewersFilter.length) {
    return performanceRoundSummaryRating || {}
  }
  const skills = performanceRoundSummaryRating?.skills || []
  return {
    ...(performanceRoundSummaryRating || {}),
    skills: skills.reduce<InterviewRoundSummaryRatingInterface[]>((acc, curr) => {
      const currInterviewers = curr.interviewer_ratings.filter(({ interviewer }) => {
        return interviewersFilter.includes(interviewer.id)
      })
      if (currInterviewers.length) {
        acc.push({
          ...curr,
          interviewer_ratings: currInterviewers,
        })
      }
      return acc
    }, []),
  }
}

export const PerformanceSections = ({
  performanceRoundSummary,
}: PerformanceSectionsProps) => {
  const [interviewersFilter, setInterviewersFilter] = useState<number[]>([])
  const { delivery, skill, value } = useMemo(() => {
    return {
      delivery: filterRatingsByInterviewers(
        interviewersFilter,
        performanceRoundSummary?.skill_ratings?.delivery,
      ) as PerformanceHiringRoundSummaryRatingInterface,
      skill: filterRatingsByInterviewers(
        interviewersFilter,
        performanceRoundSummary?.skill_ratings?.skill,
      ) as PerformanceHiringRoundSummaryRatingInterface,
      value: filterRatingsByInterviewers(
        interviewersFilter,
        performanceRoundSummary?.skill_ratings?.value,
      ) as PerformanceHiringRoundSummaryRatingInterface,
    }
  }, [interviewersFilter, performanceRoundSummary?.skill_ratings])
  return (
    <VStack gap="s-16">
      <FilterBar
        performanceRoundSummary={performanceRoundSummary}
        interviewersFilter={interviewersFilter}
        onChangeInterviewersFilter={filter => {
          setInterviewersFilter(filter)
        }}
      />
      <VStack gap="s-16" px="s-16">
        <PerformanceSection
          grade={delivery?.average_rating}
          type="delivery"
          title="Deliverables"
        >
          <PerformanceSectionTable
            data={delivery?.skills}
            name={TableNames.PerformanceHiringSummaryDeliverables}
          />
        </PerformanceSection>
        <PerformanceSection grade={skill?.average_rating} type="skill" title="Skills">
          <PerformanceSectionTable
            data={skill?.skills}
            name={TableNames.PerformanceHiringSummarySkills}
          />
        </PerformanceSection>
        <PerformanceSection grade={value?.average_rating} type="value" title="Values">
          <PerformanceSectionTable
            data={value?.skills}
            name={TableNames.PerformanceHiringSummaryValues}
          />
        </PerformanceSection>
      </VStack>
    </VStack>
  )
}

export const PerformanceSectionsSkeleton = () => {
  return (
    <Widget>
      <VStack gap="s-16">
        <FilterBarSkeleton />
        <VStack gap="s-8">
          <PerformanceSection loading type="delivery" title="Deliverables" />
          <PerformanceSection loading type="skill" title="Skills" />
          <PerformanceSection loading type="value" title="Values" />
        </VStack>
      </VStack>
    </Widget>
  )
}
