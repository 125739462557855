import React, { useCallback, useContext, useMemo, useState } from 'react'
import {
  CandidateActiveActionType,
  InterviewFeedbackInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'

export interface CandidateProfileContextInterface {
  activeAction?: CandidateActiveActionType
  setActiveAction: React.Dispatch<
    React.SetStateAction<CandidateActiveActionType | undefined>
  >
  resetActiveAction: () => void
  activeStage?: InterviewStageWithoutRoundInterface | InterviewFeedbackInterface
  setActiveStage: React.Dispatch<
    React.SetStateAction<
      InterviewStageWithoutRoundInterface | InterviewFeedbackInterface | undefined
    >
  >
  reset: () => void
}

export const CandidateProfileContext =
  React.createContext<CandidateProfileContextInterface>({
    setActiveAction: () => {},
    resetActiveAction: () => {},
    activeAction: undefined,
    activeStage: undefined,
    setActiveStage: () => {},
    reset: () => {},
  })

type Props = {
  children: React.ReactNode
  activeActionDefault?: CandidateActiveActionType
}

export const CandidateProfileContextProvider = ({
  children,
  activeActionDefault,
}: Props) => {
  const [activeAction, setActiveAction] = useState<CandidateActiveActionType | undefined>(
    activeActionDefault,
  )
  const [activeStage, setActiveStage] = useState<
    InterviewStageWithoutRoundInterface | InterviewFeedbackInterface
  >()

  const resetActiveAction = useCallback(() => {
    setActiveAction(undefined)
  }, [setActiveAction])

  const reset = useCallback(() => {
    setActiveAction(undefined)
    setActiveStage(undefined)
  }, [setActiveAction, setActiveStage])

  const value: CandidateProfileContextInterface = useMemo(
    () => ({
      activeAction,
      setActiveAction,
      resetActiveAction,
      activeStage,
      setActiveStage,
      reset,
    }),
    [activeAction, setActiveAction, resetActiveAction, activeStage, setActiveStage],
  )

  return (
    <CandidateProfileContext.Provider value={value}>
      {children}
    </CandidateProfileContext.Provider>
  )
}

export const useCandidateProfileContext = () => {
  return useContext(CandidateProfileContext)
}
