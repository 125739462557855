import React from 'react'
import {
  InterviewRoundRatingExpectation,
  InterviewRoundSummaryRatingInterface,
} from '@src/interfaces/interviewTool'
import { PerformanceRating } from '@src/interfaces/performance'
import { Box, Flex, Icon, Tag, Token, Tooltip, useTooltip } from '@revolut/ui-kit'
import upperFirst from 'lodash/upperFirst'
import { removeUnderscore } from '@src/utils/string'
import { convertOperators } from '@src/pages/Forms/Candidate/Performance/utils'

const getTagColor = (averageRating?: string) => {
  switch (averageRating) {
    case 'unsatisfactory':
    case 'poor':
    case 'developing':
    case 'basic':
      return Token.color.greyTone50
    case 'performing':
    case 'intermediate':
      return Token.color.teal_30
    case 'exceeding':
    case 'advanced':
      return Token.color.teal_50
    case 'exceptional':
    case 'expert':
      return Token.color.teal
    default:
      return Token.color.neutral
  }
}

type AverageRatingTagProps = {
  averageRating?: PerformanceRating
}

const AverageRatingTag = ({ averageRating }: AverageRatingTagProps) => {
  return (
    <Tag
      color={getTagColor(averageRating ? convertOperators(averageRating, '', '') : '')}
    >
      {upperFirst(convertOperators(averageRating || 'Not assessed'))}
    </Tag>
  )
}

type ExpectationProps = {
  expectation?: InterviewRoundRatingExpectation
}

const ExpectationIcon = ({ expectation }: ExpectationProps) => {
  switch (expectation) {
    case 'above_expectation':
      return <Icon name="ArrowThinUp" color={Token.color.success} size={16} />
    case 'below_expectation':
      return <Icon name="ArrowThinDown" color={Token.color.warning} size={16} />
    case 'meets_expectation':
      return <Icon name="Check" color={Token.color.greyTone50} size={16} />
    default:
      return <Icon name="Dot" color={Token.color.greyTone50} size={16} />
  }
}

const Expectation = ({ expectation }: ExpectationProps) => {
  const tooltip = useTooltip()
  return (
    <Box {...tooltip.getAnchorProps()}>
      <ExpectationIcon expectation={expectation} />
      <Tooltip {...tooltip.getTargetProps()}>
        {upperFirst(removeUnderscore(expectation || 'Not assessed'))}
      </Tooltip>
    </Box>
  )
}

type ResultTagProps = {
  data: InterviewRoundSummaryRatingInterface
}

export const ResultTag = ({ data }: ResultTagProps) => {
  return (
    <Flex gap="s-4" alignItems="center" justifyContent="end">
      <AverageRatingTag averageRating={data?.average_rating} />
      <Expectation expectation={data?.expectation} />
    </Flex>
  )
}
