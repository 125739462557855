import React from 'react'
import {
  Avatar,
  AvatarSkeleton,
  HStack,
  Text,
  TextSkeleton,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { ResolveIconType } from '@revolut/ui-kit/types/dist/components/Icon/utils'
import { upperFirst } from 'lodash'
import { css } from 'styled-components'
import { getGradeColor } from '@src/pages/Forms/Candidate/Performance/utils'

const avatarCss = css`
  svg circle {
    stroke-width: 10px;
  }
`

const getGradeIconName = (grade?: string): ResolveIconType | undefined => {
  switch (grade) {
    case 'unsatisfactory':
      return '16/ExclamationMarkSign'
    case 'developing':
      return 'TurboTransfer'
    case 'performing':
      return 'SocialLike'
    case 'exceeding':
      return 'Trophy'
    case 'exceptional':
      return 'Cleaning'
    default:
      return 'QuestionSign'
  }
}

type OverallGradeProps = {
  grade?: string
  loading: boolean
}

export const OverallGrade = ({ grade, loading }: OverallGradeProps) => {
  if (loading) {
    return (
      <HStack gap="s-16" align="center" data-testid="performance-overall-loading">
        <AvatarSkeleton size={76} />
        <VStack>
          <TextSkeleton variant="heading3" width="120px" />
          <Text variant="body2" whiteSpace="nowrap">
            Overall grade
          </Text>
        </VStack>
      </HStack>
    )
  }
  const color = getGradeColor(grade)
  return (
    <HStack gap="s-16" align="center" data-testid="performance-overall">
      <Avatar
        css={avatarCss}
        color={color}
        progress={1}
        progressColor={color}
        size={76}
        useIcon="Trophy"
      >
        {grade && (
          <Avatar.Badge
            backgroundColor={color}
            color={Token.color.white}
            position="top-right"
            useIcon={getGradeIconName(grade)}
            size={24}
          />
        )}
      </Avatar>
      <VStack>
        <Text color={color} variant="heading3" whiteSpace="nowrap">
          {upperFirst(grade) || 'Not assessed'}
        </Text>
        <Text variant="body2" whiteSpace="nowrap">
          Overall grade
        </Text>
      </VStack>
    </HStack>
  )
}
