import React from 'react'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import FilterButtonCheckboxSelect from '@src/components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import { OptionInterface } from '@src/interfaces/selectors'

type StageTypeFilterProps = {
  value: OptionInterface[]
  onChange: (values: OptionInterface[]) => void
}

export const StageTypeFilter = ({ value, onChange }: StageTypeFilterProps) => {
  const { data: stageTypes } = useGetSelectors(selectorKeys.hiring_stage_types)
  return (
    <FilterButtonCheckboxSelect
      searchable
      value={value}
      options={stageTypes || []}
      label="Interview types"
      onChange={newValue => {
        if (newValue) {
          onChange(newValue)
        } else {
          onChange([])
        }
      }}
    />
  )
}
