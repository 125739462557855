import React from 'react'
import { PageBody } from '@src/components/Page/PageBody'
import { PerformanceOverview } from '@src/pages/Forms/Candidate/Performance/PerformanceOverview'
import { Token, VStack } from '@revolut/ui-kit'
import {
  InterviewFeedbackInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import { PerformanceSummary } from '@src/pages/Forms/Candidate/Performance/Summary/PerformanceSummary'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'
import { InterviewFeedbackSidebar } from '@src/pages/Forms/Candidate/InterviewProgress/components/InterviewFeedbackSidebar/InterviewFeedbackSidebar'

type PerformanceProps = {
  round?: InterviewRoundInterface
}

export const Performance = ({ round }: PerformanceProps) => {
  const { activeStage, activeAction, reset } = useCandidateProfileContext()
  return (
    <>
      <PageBody maxWidth={Token.breakpoint.lg}>
        <VStack gap="s-16">
          <PerformanceOverview round={round} />
          <PerformanceSummary round={round} />
        </VStack>
      </PageBody>
      {activeAction?.type === 'stage' && (
        <InterviewFeedbackSidebar
          key={activeStage?.id}
          isOpen
          onExit={() => {
            reset()
          }}
          scorecard={activeStage as InterviewFeedbackInterface}
          title={(activeStage as InterviewFeedbackInterface)?.interviewer?.display_name}
          round={round}
        />
      )}
    </>
  )
}
