import React, { useMemo } from 'react'
import { FilterButton, FilterButtonSkeleton, Flex } from '@revolut/ui-kit'
import { PerformanceHiringRoundSummaryInterface } from '@src/interfaces/interviewTool'
import uniqBy from 'lodash/uniqBy'
import { EmployeeFilter } from '@src/pages/Forms/Candidate/Performance/components/EmployeeFilter'

export const FilterBarSkeleton = () => {
  return (
    <Flex gap="s-8" px="s-16">
      <FilterButton useIcon="People" labelClear="All" active>
        All
      </FilterButton>
      <FilterButtonSkeleton />
      <FilterButtonSkeleton />
    </Flex>
  )
}

type FilterBarProps = {
  performanceRoundSummary?: PerformanceHiringRoundSummaryInterface
  interviewersFilter: number[]
  onChangeInterviewersFilter: (interviewersFilter: number[]) => void
}

export const FilterBar = ({
  performanceRoundSummary,
  interviewersFilter,
  onChangeInterviewersFilter,
}: FilterBarProps) => {
  const uniqueInterviewers = useMemo(() => {
    if (!performanceRoundSummary?.skill_ratings) {
      return []
    }
    const { delivery, skill, value } = performanceRoundSummary.skill_ratings
    const interviewers = [
      ...(delivery?.skills || []),
      ...(skill?.skills || []),
      ...(value?.skills || []),
    ].flatMap(rating =>
      rating.interviewer_ratings.flatMap(interview => interview.interviewer),
    )
    return uniqBy(interviewers, 'id')
  }, [performanceRoundSummary])
  return (
    <Flex gap="s-8" px="s-16" flexWrap="wrap">
      <FilterButton
        useIcon="People"
        labelClear="All"
        active={!interviewersFilter.length}
        onClick={() => {
          onChangeInterviewersFilter([])
        }}
      >
        All
      </FilterButton>
      {uniqueInterviewers.map(interviewer => {
        const { id, full_name } = interviewer
        const filtering = interviewersFilter.includes(id)
        return (
          <EmployeeFilter
            key={id}
            active={interviewersFilter.includes(id)}
            userWithAvatarProps={{ ...interviewer }}
            onClick={() => {
              onChangeInterviewersFilter(
                filtering
                  ? interviewersFilter.filter(fid => fid !== id)
                  : [...interviewersFilter, id],
              )
            }}
          >
            {full_name}
          </EmployeeFilter>
        )
      })}
    </Flex>
  )
}
