import React from 'react'
import {
  AvatarSkeleton,
  chain,
  Flex,
  HStack,
  Tag,
  Text,
  TextButton,
  TextSkeleton,
  Token,
  VStack,
} from '@revolut/ui-kit'
import {
  InterviewFeedbackInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { formatDateTime } from '@src/utils/format'
import { getFeedbackBadge } from '@src/pages/Forms/Candidate/StagesTable/StagesTableFeedback'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LocalStorageKeys } from '@src/store/auth/types'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'

export const OverallImpressionFeedbackSkeleton = () => {
  const [persistTheme] = useLocalStorage(LocalStorageKeys.THEME, 'dark')
  const backgroundColor =
    persistTheme === 'dark'
      ? Token.color.popoverBackground
      : Token.color.groupedBackground
  return (
    <VStack
      bg={backgroundColor}
      gap="s-8"
      p="s-16"
      style={{ borderRadius: Token.radius.r16 }}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <HStack align="center">
          {chain(
            <HStack gap="s-4">
              <AvatarSkeleton size={24} />
              <TextSkeleton width={50} />
            </HStack>,
            <TextSkeleton width={50} />,
          )}
        </HStack>
        <HStack gap="s-4">
          <Tag variant="outlined">
            <TextSkeleton width={50} />
          </Tag>
          <Tag variant="outlined">
            <TextSkeleton width={50} />
          </Tag>
        </HStack>
      </Flex>
      <TextSkeleton width="100%" />
    </VStack>
  )
}

type ResultTagProps = {
  interviewFeedback: InterviewFeedbackInterface
}

const ResultTag = ({ interviewFeedback }: ResultTagProps) => {
  const badge = getFeedbackBadge(
    interviewFeedback?.result || undefined,
    interviewFeedback.status,
  )
  return (
    <Tag variant="outlined" useIcon={badge.icon} color={Token.color.deepGrey}>
      {badge.text}
    </Tag>
  )
}

type OverallImpressionFeedbackProps = {
  interviewFeedback: InterviewFeedbackInterface
  stage: InterviewStageWithoutRoundInterface
}

export const OverallImpressionFeedback = ({
  interviewFeedback,
  stage,
}: OverallImpressionFeedbackProps) => {
  const { setActiveStage, setActiveAction, activeStage, reset } =
    useCandidateProfileContext()
  const [persistTheme] = useLocalStorage(LocalStorageKeys.THEME, 'dark')
  const backgroundColor =
    persistTheme === 'dark'
      ? Token.color.popoverBackground
      : Token.color.groupedBackground
  return (
    <VStack
      bg={backgroundColor}
      gap="s-4"
      p="s-16"
      style={{ borderRadius: Token.radius.r16 }}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <HStack align="center">
          {chain(
            <UserWithAvatar {...interviewFeedback.interviewer} />,
            <Text variant="body2" color={Token.color.greyTone50}>
              {formatDateTime(interviewFeedback.updated_date_time)}
            </Text>,
          )}
        </HStack>
        <HStack gap="s-8">
          <Tag variant="outlined" useIcon="Microphone" color={Token.color.deepGrey}>
            {stage.title}
          </Tag>
          <ResultTag interviewFeedback={interviewFeedback} />
        </HStack>
      </Flex>
      <Text>{interviewFeedback.overall_impressions}</Text>
      {!!interviewFeedback.scorecard && (
        <TextButton
          onClick={() => {
            if (activeStage?.id === interviewFeedback?.id) {
              reset()
            } else {
              setActiveStage(interviewFeedback)
              setActiveAction({
                type: 'stage',
              })
            }
          }}
        >
          View scorecard
        </TextButton>
      )}
    </VStack>
  )
}
