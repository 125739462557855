import React from 'react'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { useGetCandidatePerformanceProfile } from '@src/api/recruitment/interviews'
import { Avatar, Item, ItemSkeleton, Widget } from '@revolut/ui-kit'
import {
  PerformanceSections,
  PerformanceSectionsSkeleton,
} from '@src/pages/Forms/Candidate/Performance/Summary/PerformanceSections'
import {
  OverallImpressions,
  OverallImpressionsSkeleton,
} from '@src/pages/Forms/Candidate/Performance/Summary/OverallImpressions'

type PerformanceSummaryProps = {
  round?: InterviewRoundInterface
}

export const PerformanceSummary = ({ round }: PerformanceSummaryProps) => {
  const { data, isLoading } = useGetCandidatePerformanceProfile(round?.id)
  if (isLoading) {
    return (
      <Widget pb="s-16" data-testid="loading-performance-summary">
        <ItemSkeleton />
        <PerformanceSectionsSkeleton />
        <OverallImpressionsSkeleton />
      </Widget>
    )
  }
  return (
    <Widget>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="BarChart" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Performance summary</Item.Title>
        </Item.Content>
      </Item>
      <PerformanceSections performanceRoundSummary={data} />
      <OverallImpressions round={round} />
    </Widget>
  )
}
